import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function dashboard(state = initState, action) {
  //console.log(action)
  switch (action.type) {
    case CONSTANTS.DASHBOARD.SUCCESS:
        return {
            data:action.result,
            graph:[...state.graph]
        };
    case CONSTANTS.DASHBOARD.FAILURE:
        return {
            message:action.message,
            data:[...state.data],
            graph:[...state.graph]
        };
    case CONSTANTS.DASHBOARD.GRAPH_SUCCESS:
        return {
            graph:action.result
        };
    case CONSTANTS.DASHBOARD.GRAPH_FAILURE:
        return {
            message:action.message,
            graph:[...state.graph]
        };
    case CONSTANTS.CLEAR:
        return initState
    default:
        return state
  }
}