import { CONSTANTS } from '../_constants';
let initState = {
  data: [],
  dataList: [],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1
  },
  searchValue: '',
  dataSearch: [],
}

export function users(state = initState, action) {
  //console.log(state)
  let __state = {}
  switch (action.type) {
    case CONSTANTS.USER.LIST_SUCCESS:
      let _data = []
      for (let i = 0; i < action.result.length; i++) {

        if (i < 10) {
          _data.push(action.result[i])
        }
      }
      console.log("check _data", _data)

      state = {
        ...state,
        data: action.result,
        dataList: _data,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
        },
        error: null
      };
      return state;
    case CONSTANTS.USER.LIST_FAILURE:
      if (typeof (state.data) === 'undefined') {
        state.message = action.err
        return state
      }
      return {
        message: action.message,
        data: [...state.data]
      };

    case "USERS_SEARCH":
      __state = { ...state }
      __state.dataList = []
      __state.dataSearch = []

      __state.pagination.currentPage = 1
      __state.pagination.searchValue = action.result
      state.data.forEach(element => {
        if (element.username.includes(action.result)) {
          __state.dataSearch.push(element)
        }
      });

      __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

      _j = 0
      for (let i = 0; i < __state.dataSearch.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.dataSearch[i])
          _j++
        }
      }

      state = {
        ...__state
      };
      return state;
      break;
    case CONSTANTS.USER.UPDATE_FAILURE:
      return {
        message: action.message,
        data: [...state.data]
      }
    case CONSTANTS.USER.CHANGE_PASSWORD_SUCCESS:
      return {
        message: action.message,
        data: [...state.data]
      };
    case CONSTANTS.USER.CHANGE_PASSWORD_FAILURE:
      return {
        message: action.message,
        data: [...state.data]
      };
    case CONSTANTS.USER.LOGS_COUNT_CHANGE:
      __state = { ...state }
      __state.pagination.itemPerPage = action.result
      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.data.length / action.result)

      __state.dataList = []
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.result) {
          __state.dataList.push(__state.data[i])
        }
      }

      state = {
        ...__state
      };
      return state;
    case CONSTANTS.USER.LOGS_PAGE_CHANGE:
      __state = { ...state }
      __state.pagination.currentPage = action.result

      __state.dataList = []
      let _j = 0
      for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {

        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i])
          _j++

        }
      }

      state = {
        ...__state
      };
      return state;
    
      case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}