import { CONSTANTS } from '../_constants';
let initState = {
    data: [],
    locationList: [
        {
            name: '',
            latitude: 0,
            longitude: 0,
            isDeleted: 0,
            id: 0,
            tanks: []

        }
    ],
    alertList: [
        {
            value: '',
            paramId: '',
            sensorType: '',
            tankName: '',
            locationName: '',
            message: ''
        }
    ],
    locationData: [],
    location: [],
    tank: [],
    paramdetail: [],
    pagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
    tankDetail: []
}

export function location(state = initState, action) {
    let __state = {}
    let _data = action.result

    switch (action.type) {
        case CONSTANTS.LOCATION.LIST_SUCCESS:
            state = updateLocationList(state, action.result)
            break
        case CONSTANTS.LOCATION.LIST_FAILURE:
            if (typeof (state.data) === 'undefined') {
                state.message = action.err
                return state
            }
            return {
                message: action.message,
                data: [...state.data]
            };

        case CONSTANTS.LOCATION.LOGS_COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.itemPerPage = action.result
            __state.pagination.currentPage = 1
            __state.pagination.totalPage = Math.ceil(__state.locationData.length / action.result)

            __state.locationList = []
            for (let i = 0; i < __state.locationData.length; i++) {
                if (i < action.result) {
                    __state.locationList.push(__state.locationData[i])
                }
            }

            state = {
                ...__state
            };
            return state;
        case CONSTANTS.LOCATION.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result

            __state.locationList = []
            let _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.locationData.length; i++) {

                if (_j < state.pagination.itemPerPage) {
                    __state.locationList.push(__state.locationData[i])
                    _j++

                }
            }

            state = {
                ...__state
            };
            return state;

        case CONSTANTS.LOCATION.DETAIL_LIST_SUCCESS:
            return {
                ...state,
                locationInfo: _data
            }


        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }

    return state
}

const updateLocationList = (state, data) => {
    let { ..._state } = state


    let _data = []
    let alertdata = []

    for (let i = 0; i < data.res.length; i++) {

        if (i < 10) {
            _data.push(data.res[i])
        }

    }

    _state = {
        ..._state,
        paramdetail: data.paramdetail,
        locationData: data.res,
        locationList: _data,
        pagination: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: Math.ceil(data.res.length / state.pagination.itemPerPage)
        },
        error: null
    };
    return _state;
}