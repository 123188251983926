import { getAuthUserAccessToken } from './auth'
import { removeAuthUser } from '.'
import { CONSTANTS } from '../_constants'

export function Post(url, data){
    return PostQuery(url, data)
}

export function PostQuery(url, data, query){
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
    };
    if(typeof data !== 'undefined'){
        requestOptions.body = JSON.stringify(data)
    }
    // http://localhost:3006/api/Devices/update?where=%7B%22id%22%3A%224%22%7D
    // http://localhost:3006/api/AppUsers/login?include=user

    // query = {include:[]},{where:{}}
    // login?include=user
    // update?where=%7B%22id%22%3A%225%22%7D
    let querys = ''
    if(typeof query !== 'undefined'){
        let keys = Object.keys(query);
        //querys = `?${keys[0]}=${query[keys[0]]}`
        querys = `?${keys[0]}=${JSON.stringify(query[keys[0]])}`
    }
    //console.log(querys)

    // Return new promise 
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}${querys}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN' ){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            // Error: Authorization Required
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                        
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function UploadFile(url, data){
    let requestOptions = {
        method: 'POST',
        headers: { 'Authorization': getAuthUserAccessToken() },
    };
    if(typeof data !== 'undefined'){
        requestOptions.body = data
    }
    
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN' ){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            // Error: Authorization Required
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                        
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function Get(url){
    return GetQuery(url)
}

export function GetQuery(url, query){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
    };
    
    if(typeof query === 'undefined'){
        query = ''
    }
    else{
        query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }
    
    //?filter=%7B%22and%22%3A%5B%7B%22timestamp%22%3A%7B%22gt%22%3A%222019-02-12T12%3A00%3A00Z%22%7D%7D%2C%7B%22status%22%3A%22pick%22%7D%5D%7D
    // Return new promise 
    return new Promise((resolve, reject)=> {
        // Do async job
        fetch(`${url}${query}`, requestOptions)
        .then((response)=>{
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                // console.log('========================')
                                // console.log(data)
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function GetQueryParam(url, query){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
    };
    
    let __query = ''
    if(typeof query !== 'undefined'){
        let __str = '?'
        for(const key in query){
            __str += key + "="+query[key]+"&"
        }
        __query = __str.substr(0, __str.length-1)
    }
    
    //?filter=%7B%22and%22%3A%5B%7B%22timestamp%22%3A%7B%22gt%22%3A%222019-02-12T12%3A00%3A00Z%22%7D%7D%2C%7B%22status%22%3A%22pick%22%7D%5D%7D
    // Return new promise 
    return new Promise((resolve, reject)=> {
        // Do async job
        fetch(`${url}${__query}`, requestOptions)
        .then((response)=>{
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                // console.log('========================')
                                // console.log(data)
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function Patch(url, data){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
        body: JSON.stringify(data)
    };

    // Return new promise 
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        //console.log('==========================')
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    //console.log('--------------------------------')
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function Delete(url){
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() }
    };

    // Return new promise 
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        //console.log('==========================')
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    //console.log('--------------------------------')
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function DeleteQuery(url, query){
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() }
    };

    if(typeof query === 'undefined'){
        query = ''
    }
    else{
        query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }

    // Return new promise 
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}${query}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        //console.log('==========================')
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    //console.log('--------------------------------')
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function PutQuery(url, data, query){
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
        body: JSON.stringify(data)
    };
    
    if(typeof query === 'undefined'){
        query = ''
    }
    else{
        query = `?filter=${encodeURIComponent(JSON.stringify(query))}`
    }
    
    //?filter=%7B%22and%22%3A%5B%7B%22timestamp%22%3A%7B%22gt%22%3A%222019-02-12T12%3A00%3A00Z%22%7D%7D%2C%7B%22status%22%3A%22pick%22%7D%5D%7D
    // Return new promise 
    return new Promise((resolve, reject)=> {
        // Do async job
        fetch(`${url}${query}`, requestOptions)
        .then((response)=>{
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                // console.log('========================')
                                // console.log(data)
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    reject('Error on response data.');
                }
            })
        })
    })
}

export function Put(url, data){
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': getAuthUserAccessToken() },
        body: JSON.stringify(data)
    };

    // Return new promise 
    return new Promise(function(resolve, reject) {
        // Do async job
        fetch(`${url}`, requestOptions)
        .then(function(response){
            response.text().then(text => {
                try {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        //console.log('==========================')
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            if(data.error.code==='INVALID_TOKEN'){
                                removeAuthUser()
                                //console.log(response)
                                //console.log(data.error.code)//:"INVALID_TOKEN")
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                            else if(data.error.code==='AUTHORIZATION_REQUIRED'){
                                removeAuthUser()
                                window.location.assign(CONSTANTS.PATH.LOGIN)
                            }
                        }
                
                        let error = response.statusText;
                        //console.log(error)
                        if(data.error){
                            error = data.error.message
                        }
                        
                        //console.log(error)
                        return reject(error);
                    }
                    //console.log(data)
                    return resolve(data)
                }
                catch(e) {
                    //console.log(e)
                    //console.log('--------------------------------')
                    reject('Error on response data.');
                }
            })
        })
    })
}