import { Post, GetQuery,Delete } from '../../_innates_func';
import { SERVER, CONSTANTS } from '../../_constants'

class AppTank {

    getTankList(param){
        return GetQuery(SERVER.API.AppTank.List, param)
    }

    deleteTank(id){
        return Delete(SERVER.API.AppTank.DeleteOne+id)
    }

    updateTank(filter,param){
        return Post(SERVER.API.AppTank.EditOne+filter, param)
    }

    createTank(param){
        return Post(SERVER.API.AppTank.createTank, param)
    }

    getDetailTankList(param){
        return GetQuery(SERVER.API.AppTank.List, param)
    }

}

export default AppTank;
