import { CONSTANTS } from '../_constants';
import { getAuthUser } from '../_innates_func/auth';

let user = getAuthUser();
const initialState = user ? { loggedIn: true, user } : {};

export function authorization(state = initialState, action) {
  //console.log(action)
  switch (action.type) {
    case CONSTANTS.AUTHORIZATION.LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case CONSTANTS.AUTHORIZATION.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user:action.res
      };
    case CONSTANTS.AUTHORIZATION.LOGIN_FAILURE:
      return {
        response: true,
        message: action.message,
      };
    case CONSTANTS.AUTHORIZATION.LOGOUT_REQUEST:
      return {
        loggedIn: true,
        user: {...state.user},
        loggingOut: true
      };
    case CONSTANTS.AUTHORIZATION.LOGOUT_SUCCESS:
      return {}
    case CONSTANTS.AUTHORIZATION.LOGOUT_FAILURE:
      return {}
    case "UPDATE_NAME":
      return{
        loggedIn: true,
        user: {...state.user},
        name: action.data.name,
        profile_pic: action.data.profile_pic
      }
    default:
      return state
  }
}