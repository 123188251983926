const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// const defaultFormatDate = 'yyyy-mm-dd'  // 2019-05-05
// const defaultFormatTime = 'HH:MM'       // 16:05

var DateFormat = require('dateformat');

class Dates{
    static initDate(datetime){
        if(typeof(datetime)==='undefined' || datetime === null){
            return new Date()
        }
        else{
            return new Date(datetime)
        }
    }


    // datetime refer to local time
    // return Sunday,..
    static getDayName(datetime){
        return days[this.initDate(datetime).getDate()]
    }
    // datetime refer to local time
    // return Januari,..
    static getMonthName(datetime){
        return months[this.initDate(datetime).getMonth()]
    }
    // 1-12
    static getMonthNameByNumber(number){
        if(number < 1 || number > 12){
            return null
        }
        return months[number - 1]
    }

    // datetime refer to local time
    // return 01,...31
    static getDateNumber(datetime){
        let _d = this.initDate(datetime).getDate()
        if(_d.toString().length === 1){
            _d = '0'+_d.toString()
        }
        return _d
    }
    // datetime refer to local time
    // return 01,...12
    static getMonthNumber(datetime){
        let m_ = this.initDate(datetime).getMonth()+1
        if(m_.toString().length === 1){
            m_ = '0'+m_.toString()
        }
        return m_
    }
    // datetime refer to local time
    // return 2019,...
    static getYear(datetime){
        return this.initDate(datetime).getFullYear()
    }
    // dates = '2019-02-17'
    // format = yyyy-MM-dd, dd-MM-yyyy
    // return '2019-02-17' | current date
    static getDates(dates){
        let d = this.initDate(dates)
        let m_ = d.getMonth()+1
        if(m_.toString().length === 1){
            m_ = '0'+m_.toString()
        }
        return (d.getFullYear()+'-'+m_+'-'+d.getDate())
    }
    // dates = '2019-02-17'
    // return '20:45:23' | current times
    static getTime(dates){
        let d = new this.initDate(dates)

        let _h = d.getHours()
        if(_h.toString().length === 1){
            _h = '0'+_h.toString()
        }
        let _m = d.getMinutes()
        if(_m.toString().length === 1){
            _m = '0'+_m.toString()
        }
        let _s = d.getSeconds()
        if(_s.toString().length === 1){
            _s = '0'+_s.toString()
        }

        return (_h+':'+_m+':'+_s)
    }
    // datetime = 2019-02-04 00:25:69
    static getDateTime(datetime){
        return this.getDates(datetime)+' '+this.getTime(datetime)
    }
    static getDateTimeTZ(datetime){
        return Dates.getDates(datetime)+'T'+Dates.getTime(datetime)+'Z'
    }

    static getMasDateTime(){
        return this.getDateNumber()+'/'+this.getMonthNumber()+'/'+this.getYear()+' '+this.getTime()
    }

    //======================================utc=======================
    // datetime refer to utc time
    // return Sunday,..
    static getUTCDayName(datetime){
        return days[this.initDate(datetime).getUTCDate()]
    }
    // datetime refer to utc time
    // return Januari,..
    static getUTCMonthName(datetime){
        return months[this.initDate(datetime).getUTCMonth()]
    }
    // datetime refer to utc time
    // return 01,...31
    static getUTCDateNumber(datetime){
        let _d = this.initDate(datetime).getUTCDate()
        if(_d.toString().length === 1){
            _d = '0'+_d.toString()
        }
        return _d
    }
    // datetime refer to utc time
    // return 01,...12
    static getUTCMonthNumber(datetime){
        let m_ = this.initDate(datetime).getUTCMonth()+1
        if(m_.toString().length === 1){
            m_ = '0'+m_.toString()
        }
        return m_
    }
    // datetime refer to utc time
    // return 2019,...
    static getUTCYear(datetime){
        return this.initDate(datetime).getUTCFullYear()
    }
    // dates = '2019-02-17'
    // return '2019-02-17' | current date
    static getUTCDates(dates){
        let d = this.initDate(dates)
        let m_ = d.getUTCMonth()+1
        if(m_.toString().length === 1){
            m_ = '0'+m_.toString()
        }
        let _d = d.getUTCDate()
        if(_d.toString().length === 1){
            _d = '0'+_d.toString()
        }
        return (d.getUTCFullYear()+'-'+m_+'-'+_d)
    }
    // dates = '2019-02-17'
    // return '20:45:23' | current times
    static getUTCTime(dates){
        let d = new this.initDate(dates)

        let _h = d.getUTCHours()
        if(_h.toString().length === 1){
            _h = '0'+_h.toString()
        }
        let _m = d.getUTCMinutes()
        if(_m.toString().length === 1){
            _m = '0'+_m.toString()
        }
        let _s = d.getUTCSeconds()
        if(_s.toString().length === 1){
            _s = '0'+_s.toString()
        }

        return (_h+':'+_m+':'+_s)
    }
    // datetime = 2019-02-04 00:25:69
    static getUTCDateTime(datetime){
        return (this.getUTCDates(datetime)+' '+this.getUTCTime(datetime))
    }
    static getUTCDateTimeTZ(datetime){
        return (this.getUTCDates(datetime)+'T'+this.getUTCTime(datetime)+'Z')
    }
    static getDiffTime(date_from, date_to){
        let _diff = Math.abs(new Date(date_from) - new Date(date_to)) / 1000
        var year = Math.floor(_diff / 946080000)
        var month = Math.floor(_diff / 2592000)%30;
        var days = Math.floor(_diff / 86400);       
        var hours = Math.floor(_diff / 3600) % 24;
        var minutes = Math.floor(_diff / 60) % 60;
        var seconds = Math.floor(_diff % 60);
    
        return({year:year,month:month,day:days,hour:hours,minute:minutes,second:seconds})
    }

    static getDiffTimeByDay(date_from, date_to){
        //console.log(date_from)
        let _diff = Math.abs(new Date(date_from) - new Date(date_to)) / 1000
        return Math.floor(_diff / 86400);
    }






    // ==================== new ============================
    static getDateTimeWithFormat(datetime, format){
        // console.log(datetime)
        if(typeof(datetime)==='undefined'){
            return ''
        }
        if(datetime===null){
            return ''
        }
        
        let dates = DateFormat(datetime, format)
        return dates
    }
}

export default Dates;