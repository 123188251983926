import { CONSTANTS, SERVER } from '../_constants'
import AppTank from '../_models/Tank'

const moment = require('moment');

const _AppTank = new AppTank()
const mqtt = require('mqtt');

var client = null;
var mainProps = {};

export function setProps(prop) {
    mainProps = prop
}

export function mqttConnect() {
    console.log('mqttConnect')
    client = mqtt.connect(SERVER.API.Mqtt.URL, {
        username: 'wms_dash',
        password: 'asdh@453bia&2sfeeYq3rs',
        clientId: 'web_mqtt_pvems' + Math.random().toString(16).substr(2, 8),
    });

    client.on('connect', function () {
    console.log("Connected to broker");
        client.subscribe('#');
    })
    client.on('message', function (topic, message) {

        if (topic === 'parameterData') {

            let data = JSON.parse(message.toString())

            mainProps.dispatch({ type: 'LIVE_DATA', data: data })

            let date = moment(new Date()).format("YYYY-MM-DD")
            let filter = {"include":{"relation":"sensors","scope":{"include":{"relation":"parameters","scope":{"include":{"relation":"param", "scope":{"where":{"updatedAt":{"gt":date}}}}}}}}}
            _AppTank.getDetailTankList(filter).then(result => {
                console.log('result details param ==> ', result)
                mainProps.dispatch({ type: CONSTANTS.TANK.DETAIL_LIST_SUCCESS, result });
            })
        } else if (topic === 'lowAlert') {

            let data = JSON.parse(message.toString())

            mainProps.dispatch({ type: 'LIVE_LOW_ALERT_DATA', data: data })
            
        } else if (topic === 'highAlert'){

            let data = JSON.parse(message.toString())

            mainProps.dispatch({ type: 'LIVE_HIGH_ALERT_DATA', data: data })
            
        }


    });
    client.on('error', function (err) {
        console.log('MQTT error: ' + err);
    });
    client.on('close', function (err) {
        console.log('MQTT close: ' + err);
    });
}
export function mqttClose() {
    if (client !== null) {
        client.end()
    }
}
