import { createStore } from 'redux';
import rootReducer from '../_reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

//---------------------
import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
);