import { CONSTANTS } from '../_constants';
let initState = {
    data: [],
    parameterList: {
        groupDaily:[
            {
                dataPerHour:{
                    dataPerMinute:[]
                }
            }
        ]
    },
    parameterData: {
        groupDaily:[
            {
                dataPerHour:{
                    dataPerMinute:[]
                }
            }
        ]
    },
    readingList : [],
    readingData : [],
    pagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1,
    },
    minutePagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1,
    },
    print: {
        data: {},
        status: 'pending'
    },
    searchValue: '',
    dataSearch: [],
}

export function parameterData(state = initState, action) {
    let __state = {}
    let data;
    switch (action.type) {

        case CONSTANTS.PARAMETERDATA.DATA_HISTORY_LIST_FAILURE:
            if (typeof (state.data) === 'undefined') {
                state.message = action.err
                return state
            }
            return {
                message: action.message,
                data: [...state.data]
            };
        case CONSTANTS.PARAMETERDATA.LOGS_COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.itemPerPage = action.result
            __state.pagination.currentPage = 1
            __state.pagination.totalPage = Math.ceil(__state.parameterList.groupDaily.length / action.result)

            __state.parameterData.groupDaily = []
            for (let i = 0; i < __state.parameterList.groupDaily.length; i++) {
                if (i < action.result) {
                    __state.parameterData.groupDaily.push(__state.parameterList.groupDaily[i])
                }
            }

            state = {
                ...__state
            };
            return state;
        case CONSTANTS.PARAMETERDATA.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result

            __state.parameterData.groupDaily = []
            let _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.parameterList.groupDaily.length; i++) {

                if (_j < state.pagination.itemPerPage) {
                    __state.parameterData.groupDaily.push(__state.parameterList.groupDaily[i])
                    _j++

                }
            }

            state = {
                ...__state
            };
            return state;
        case CONSTANTS.PARAMETERDATA.DATA_HISTORY_FILTERED_LIST_SUCCESS:
            state = updateParameterList(state, action.payload.data)
            break;
        case CONSTANTS.PARAMETERDATA.DATA_HISTORY_LIST_SUCCESS:
            state = updateParameterList(state, action.payload.data)
            break;
        case CONSTANTS.PARAMETERDATA.DATA_MINUTE_HISTORY_LIST_SUCCESS:
            let { ..._state } = state

            let _data = []
            for (let i = 0; i < action.payload.data.data.length; i++) {
      
              if (i < 10) {
                _data.push(action.payload.data.data[i])
              }
            }

            _state = {
                ..._state,
                readingList: _data,
                readingData:  action.payload.data.data,
                minutePagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.payload.data.data.length / state.pagination.itemPerPage)
                },
                error: null
            };
            return _state;
        case CONSTANTS.PARAMETERDATA.MINUTE_LOGS_COUNT_CHANGE:
                __state = { ...state }
                __state.minutePagination.itemPerPage = action.result
                __state.minutePagination.currentPage = 1
                __state.minutePagination.totalPage = Math.ceil(__state.readingData.length / action.result)
    
                __state.readingList = []
                for (let i = 0; i < __state.readingData.length; i++) {
                    if (i < action.result) {
                        __state.readingList.push(__state.readingData[i])
                    }
                }
    
                state = {
                    ...__state
                };
                return state;
        case CONSTANTS.PARAMETERDATA.MINUTE_LOGS_PAGE_CHANGE:
                    __state = { ...state }
                    __state.minutePagination.currentPage = action.result
        
                    __state.readingList = []
                    let _k = 0
                    for (let i = ((action.result - 1) * state.minutePagination.itemPerPage); i < __state.readingData.length; i++) {
        
                        if (_k < state.minutePagination.itemPerPage) {
                            __state.readingList.push(__state.readingData[i])
                            _k++
        
                        }
                    }
        
                    state = {
                        ...__state
                    };
                    return state;
        case CONSTANTS.PARAMETERDATA.TOURS_HISTORY_CLEAR:
            state = {
                ...state,
                parameterList: [],
                parameterData: [
                    {
                        param: []
                    }
                ],
                pagination: {
                    ...state.pagination,
                    // count: 0,
                    // total: 0,
                    currentPage: 1,
                    totalPage: 1
                }
            };
            break;
        case CONSTANTS.PARAMETERDATA.TOURS_HISTORY_CURRPAGE:
            data = action.payload.index;
            state = {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: data
                }
            };
            break;
        case CONSTANTS.PARAMETERDATA.TOURS_MINUTE_HISTORY_CURRPAGE:
                data = action.payload.index;
                state = {
                    ...state,
                    minutePagination: {
                        ...state.minutePagination,
                        currentPage: data
                    }
                };
                break;
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
    return state
}

const updateParameterList = (state, data) => {
    let { ..._state } = state


    let _data = []
    let _dataGroupDaily = []
    let _dataPerMinute = []
    let dataPerMinuteess = []

    for (let i = 0; i < data.groupDaily.length; i++) {

        if (i < 10) {

            _dataGroupDaily.push(data.groupDaily[i])

            for (let y = 0; y < data.groupDaily[i].dataPerHour.length; y++) {

                _dataGroupDaily[i]['dataPerHour']=data.groupDaily[i].dataPerHour
                // _dataGroupDaily[i]['minuteItemPerPage']=10
                // _dataGroupDaily[i]['minuteCurrentPage']=1
                // _dataGroupDaily[i]['minuteTotalPage']=Math.ceil(data.groupDaily[i].dataPerHour[y].dataPerMinute.length / state.pagination.itemPerPage)

               dataPerMinuteess = []

               for (let x = 0; x < data.groupDaily[i].dataPerHour[y].dataPerMinute.length; x++) {

                //    if(x<10){
                        dataPerMinuteess.push(data.groupDaily[i].dataPerHour[y].dataPerMinute[x])
                //    }                  
               
               }

               _dataGroupDaily[i].dataPerHour[y]['dataPerMinute']=dataPerMinuteess
               
            }
        }
        
    }

    
    let dataa = {
        ...data,
        groupDaily:_dataGroupDaily
    }

    _state = {
        ..._state,
        parameterData: dataa,
        parameterList: data,
        pagination: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: Math.ceil(data.groupDaily.length / state.pagination.itemPerPage)
        },
        error: null
    };
    return _state;
}