var MAIN = ''
var URL = window.location.href
var HOSTNAME = window.location.hostname
var MQTT_URL = 'ws://'+ HOSTNAME+'/ws'
const dev = false;
if (dev) {
    MAIN = 'http://'+ HOSTNAME + ':3002/api'
    MQTT_URL = 'ws://'+ HOSTNAME + ':3888'
}else {
    MAIN = 'https://' + HOSTNAME + '/api'
    MQTT_URL = 'wss://' + HOSTNAME + '/ws'
}

export const SERVER = {
    API:{
        Mqtt: {
            URL: MQTT_URL,
        },
        AppUser: {
            MAIN: MAIN+'/FBUsers',
            
            Register: MAIN+'/FBUsers',
            Login: MAIN+'/FBUsers/login',
            Logout: MAIN+'/FBUsers/logout',
            
            ListSystemUser: MAIN+'/FBUsers/list/systemuser',
            UpdateUser: MAIN+'/FbUsers',

            ApplicantInfo: MAIN+'/Applicants',
            ApplicantTrades: MAIN+'/ApplicantTrades',
            ApplicantCertificate: MAIN+'/ApplicantCertificates',
            UploadApplicantIC: MAIN+'/Stores/Applicant%20IC/upload',
            RegisterSystemuser: MAIN+'/FBUsers/register/systemuser',
            
            UpdatePassword: MAIN+'/FBUsers/update',

            RegisterApplicant: MAIN+'/FBUsers/register/applicant',
            ResetPass: MAIN+'/FBUsers/reset/password',

            RegisterManager: MAIN+'/AppUsers/register/manager',
            RegisterInspector: MAIN+'/AppUsers/register/inspector',

            UpdateManager: MAIN+'/AppUsers/update/manager',
            UpdateInspector: MAIN+'/AppUsers/update/inspector',

            DeleteManager: MAIN+'/AppUsers/delete/manager',
            DeleteInspector: MAIN+'/AppUsers/delete/inspector',

            List: MAIN+'/FBUsers',
            DeleteOne: MAIN+'/FBUsers/',
            EditOne: MAIN+'/FBUsers/update?where=',
            createUser: MAIN+'/FBUsers/create/user',

        },

        AppLocation: {
            MAIN: MAIN+ '/Locations',
            List: MAIN+'/Locations/details',
            EditOne: MAIN+'/Locations/update?where=',
            createLocation: MAIN+'/Locations/create/location',
            DeleteOne: MAIN+'/Locations/',
        },

        AppTank: {
            List: MAIN+'/Tanks',
            EditOne: MAIN+'/Tanks/update?where=',
            createTank: MAIN+'/Tanks/create/tank',
            DeleteOne: MAIN+'/Tanks/',
        },

        AppSensor: {
            List: MAIN+'/Sensors',
            EditOne: MAIN+'/Sensors/',
            createSensor: MAIN+'/Sensors/',
            DeleteOne: MAIN+'/Sensors/',
            Delete: MAIN+'/Sensors/',
            DeleteParameter: MAIN+'/Sensors/',
            createParameter:  MAIN+'/Sensors/',
            updateParameter: MAIN+'/Sensors/',
            updateSensorByParameter: MAIN+'/Parameters/'
        },

        AppParameter: {
            List: MAIN+'/ParameterData',
            ParamDetail: MAIN+'/Parameters'
        },

        AppParameterData: {
            List: MAIN+'/ParameterData/history',
            Filtered: MAIN+'/ParameterData/filtered',
            EditOne: MAIN+'/ParameterData/'
        },


       
    }
}