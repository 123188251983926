export * from './api.links';

// Component
const AUTHORIZATION = {
    LOGIN_REQUEST: 'AUTHORIZATION_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHORIZATION_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTHORIZATION_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTHORIZATION_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHORIZATION_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTHORIZATION_LOGOUT_FAILURE',
}

const LIVE_DATA = {
    LIST_REQUEST: 'LIVE_DATA_LIST_REQUEST',
    LIST_SUCCESS: 'LIVE_DATA_LIST_SUCCESS',
    LIST_FAILURE: 'LIVE_DATA_LIST_FAILURE',
}

// View Page
const USER = {
    USER_SEARCH: 'USERS_SEARCH',
    LIST_REQUEST: 'USERS_LIST_REQUEST',
    LIST_SUCCESS: 'USERS_LIST_SUCCESS',
    LIST_FAILURE: 'USERS_LIST_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    FIND_REQUEST: 'USERS_FIND_REQUEST',
    FIND_SUCCESS: 'USERS_FIND_SUCCESS',
    FIND_FAILURE: 'USERS_FIND_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',


}
const LOCATION = {
    LOCATION_SEARCH: 'LOCATIONS_SEARCH',
    LIST_REQUEST: 'LOCATIONS_LIST_REQUEST',
    LIST_SUCCESS: 'LOCATIONS_LIST_SUCCESS',
    LIST_FAILURE: 'LOCATIONS_LIST_FAILURE',

    REGISTER_REQUEST: 'LOCATIONS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'LOCATIONS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'LOCATIONS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'LOCATIONS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LOCATIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LOCATIONS_UPDATE_FAILURE',

    FIND_REQUEST: 'LOCATIONS_FIND_REQUEST',
    FIND_SUCCESS: 'LOCATIONS_FIND_SUCCESS',
    FIND_FAILURE: 'LOCATIONS_FIND_FAILURE',

    DELETE_REQUEST: 'LOCATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'LOCATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'LOCATIONS_DELETE_FAILURE',

    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',

    DETAIL_LIST_REQUEST: 'DETAIL_LIST_REQUEST',
    DETAIL_LIST_SUCCESS: 'DETAIL_LIST_SUCCESS',

    // SEARCH: 'SEARCH_LOCATIONS'
}
const TANK = {
    SEARCH: 'TANK_SEARCH',
    LIST_REQUEST: 'TANK_LIST_REQUEST',
    LIST_SUCCESS: 'TANK_LIST_SUCCESS',
    LIST_FAILURE: 'TANK_LIST_FAILURE',

    REGISTER_REQUEST: 'TANK_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'TANK_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'TANK_REGISTER_FAILURE',

    UPDATE_REQUEST: 'TANK_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TANK_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TANK_UPDATE_FAILURE',

    FIND_REQUEST: 'TANK_FIND_REQUEST',
    FIND_SUCCESS: 'TANK_FIND_SUCCESS',
    FIND_FAILURE: 'TANK_FIND_FAILURE',

    DELETE_REQUEST: 'TANK_DELETE_REQUEST',
    DELETE_SUCCESS: 'TANK_DELETE_SUCCESS',
    DELETE_FAILURE: 'TANK_DELETE_FAILURE',

    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',

    DETAIL_LIST_REQUEST: 'DETAIL_LIST_REQUEST',
    DETAIL_LIST_SUCCESS: 'DETAIL_LIST_SUCCESS',
    DETAIL_PARAMLIST_SUCCESS: 'DETAIL_PARAMLIST_SUCCESS'

}
const DASHBOARD = {
    VIEW: 'DASHBOARD_VIEW',
    REQUEST: 'DASHBOARD_REQUEST',
    SUCCESS: 'DASHBOARD_SUCCESS',
    FAILURE: 'DASHBOARD_FAILURE',
    GRAPH_SUCCESS: 'DASHBOARD_GRAPH_SUCCESS',
    GRAPH_FAILURE: 'DASHBOARD_GRAPH_FAILURE',
}
const SENSORDATA = {
    DATA: 'DATA',
    FIND_SUCCESS: 'SENSORDATA_FIND_SUCCESS',
    FIND_FAILURE: 'SENSORDATA_FIND_FAILURE',
    DATA_AVERAGE_CURRENT: 'DATA_AVERAGE_CURRENT',
    DATA_AVERAGE_POWER: 'DATA_AVERAGE_POWER'
}
const SENSOR = {
    SEARCH: 'SENSOR_SEARCH',
    LIST_REQUEST: 'SENSOR_LIST_REQUEST',
    LIST_SUCCESS: 'SENSOR_LIST_SUCCESS',
    LIST_FAILURE: 'SENSOR_LIST_FAILURE',

    REGISTER_REQUEST: 'SENSOR_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'SENSOR_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'SENSOR_REGISTER_FAILURE',

    UPDATE_REQUEST: 'SENSOR_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SENSOR_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SENSOR_UPDATE_FAILURE',
    UPDATE_ALERT: 'SENSOR_UPDATE_ALERT',

    FIND_REQUEST: 'SENSOR_FIND_REQUEST',
    FIND_SUCCESS: 'SENSOR_FIND_SUCCESS',
    FIND_FAILURE: 'SENSOR_FIND_FAILURE',

    DELETE_REQUEST: 'SENSOR_DELETE_REQUEST',
    DELETE_SUCCESS: 'SENSOR_DELETE_SUCCESS',
    DELETE_FAILURE: 'SENSOR_DELETE_FAILURE',

    DELETE_PARAMETER_BY_SENSOR_SUCCESS: 'SENSOR_PARAMETER_DESTROYED_SUCCESS',
    DELETE_SENSOR_SUCCESS: 'SENSOR_DESTROYED_SUCCESS',
    DELETE_SENSOR_FAILURE: 'SENSOR_DESTROYED_FAILURE',
    DELETE_PARAMETER_SUCCESS:'DELETE_PARAMETER_SUCCESS',
    DELETE_PARAMETER_FAILURE:'DELETE_PARAMETER_FAILURE',

    CREATE_PARAMETER_SUCCESS: 'SENSOR_PARAMETER_CREATE_SUCCESS',
    CREATE_PARAMETER_FAILURE: 'SENSOR_PARAMETER_CREATE_FAILURE',
    UPDATE_PARAMETER_SUCCESS: 'SENSOR_PARAMETER_PUT_SUCCESS',
    UPDATE_PARAMETER_FAILURE:'SENSOR_PARAMETER_PUT_FAILURE',
    UPDATE_SENSOR_BY_PARAMETER_REQUEST: 'SENSOR_PARAMETER_PUT_PENDING',
    UPDATE_SENSOR_BY_PARAMETER_SUCCESS: 'SENSOR_PARAMETER_PUT_SUCCESS',
    UPDATE_SENSOR_BY_PARAMETER_FAILURE: 'SENSOR_PARAMETER_PUT_FAILURE',
}

const PARAMETER = {
    SEARCH: 'PARAMETER_SEARCH',
    LIST_REQUEST: 'PARAMETER_LIST_REQUEST',
    LIST_SUCCESS: 'PARAMETER_LIST_SUCCESS',
    LIST_FAILURE: 'PARAMETER_LIST_FAILURE',

    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',

}

const PARAMETERDATA = {
    DATA_HISTORY_LIST_SUCCESS: 'DATA_HISTORY_LIST_SUCCESS',
    DATA_HISTORY_LIST_FAILURE: 'DATA_HISTORY_LIST_FAILURE',
    DATA_HISTORY_FILTERED_LIST_SUCCESS: 'DATA_HISTORY_FILTERED_LIST_SUCCESS',

    DATA_MINUTE_HISTORY_LIST_SUCCESS: 'DATA_MINUTE_HISTORY_LIST_SUCCESS',
    MINUTE_LOGS_COUNT_CHANGE: 'MINUTE_LOGS_COUNT_CHANGE',
    MINUTE_LOGS_PAGE_CHANGE: 'MINUTE_LOGS_PAGE_CHANGE',

    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',

    TOURS_HISTORY_CLEAR: 'TOURS_HISTORY_CLEAR',
    TOURS_HISTORY_CURRPAGE: 'TOURS_HISTORY_CURRPAGE',
    TOURS_MINUTE_HISTORY_CURRPAGE : 'TOURS_MINUTE_HISTORY_CURRPAGE'
}


const PATH = {
    LOGIN: '/login'
}

export const CONSTANTS = {
    CLEAR: 'CLEAR',
    TIME_IDLE: 30, // value in minute
    LIVE_DATA,
    AUTHORIZATION,
    USER,
    LOCATION,
    SENSORDATA,
    SENSOR,
    TANK,
    PARAMETER,
    PARAMETERDATA,

    DASHBOARD,
    
    PATH,

    DATE_FORMAT: {
        DATE_TIME:'dd-mm-yyyy HH:MM',
        DATE: 'dd-mm-yyyy',
        TIME: 'HH:MM'
    }
}