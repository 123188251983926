import { CONSTANTS } from '../_constants';
let initState = {
    data: [],
    sensorDetail: [],
    sensors: {},
    sensorList: [],
    sensor: {},
    pagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
    alert: {
        name: {
            id: 0,
            valid: false,
            invalid: false,
            message: ''
        },
        serialNumber: {
            id: 0,
            valid: false,
            invalid: false,
            message: ''
        },
        _create: {
            id: '',
            valid: false,
            invalid: false,
            message: ''
        },
        _param: {
            id: '',
            sensor: '',
            valid: false,
            invalid: false,
            message: ''
        }
    }
}

export function sensor(state = initState, action) {

    switch (action.type) {
        case CONSTANTS.SENSOR.LIST_SUCCESS:
            state = updateSensorList(state, action.result)
            break;
        case CONSTANTS.SENSOR.LIST_FAILURE:
            if (typeof (state.data) === 'undefined') {
                state.message = action.err
                return state
            }
            return {
                message: action.message,
                data: [...state.data]
            };
        case CONSTANTS.CLEAR:
            return initState
        case CONSTANTS.SENSOR.UPDATE_ALERT:
            state = updateSensorAlert(state, action.payload)
            break;
        default:
            return state
    }
    return state
}

const updateSensorList = (state, data) => {
    let { sensors, sensorList, sensor, ..._state } = state
    sensor = {}
    sensorList = []
    console.log({data})
    // console.log(data[0].parameters)
    data.forEach(reSsensor => {
        // console.log({sensor})
        const { parameters, ..._sensor } = reSsensor
        let __sensor = {
            ..._sensor,
            byid: {},
            list: []
        }

        parameters.forEach(param => {
            __sensor.byid[param.type] = param
            __sensor.list.push(param.type)
            // console.log({param})
        })

        sensor[reSsensor.name] = reSsensor
        sensors[reSsensor.name] = __sensor
        sensorList.push(reSsensor.name)
    });

    _state = {
        ..._state,
        // data: action.result,
        // sensorDetail: _data,
        pagination: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: Math.ceil(data.length / state.pagination.itemPerPage)
        },
        sensorList: sensorList,
        sensor: sensor,
        sensors: sensors,
        error: null
    };
    return _state;
}

const updateSensorAlert = (state, payload) => {
    let { alert, ..._state } = state
    let { [payload.type]: attr, ..._alert } = alert
    let _attr = {
      ...attr,
      ...payload.status
    }
    _alert = {
      ..._alert,
      [payload.type]: _attr
    }
    _state = {
      ..._state,
      alert: _alert
    }
    return _state
  }