import { CONSTANTS } from '../_constants';
let initState = {}

export function sensordata(state = initState, action) {
  //console.log(state)
  switch (action.type) {
    case CONSTANTS.SENSORDATA.DATA:
      return {
        data: {...state.data, ...action.data}
      };
    case CONSTANTS.SENSORDATA.DATA_AVERAGE_CURRENT:
      return {
        data: {...state.data, ...action.data}
      };
    case CONSTANTS.SENSORDATA.DATA_AVERAGE_POWER:
      return {
        data: {...state.data, ...action.data}
      };
    case CONSTANTS.SENSORDATA.FIND_SUCCESS:
      return {
        data: action.data
      };
    case CONSTANTS.SENSORDATA.FIND_FAILURE:
        return {
          message:action.message,
          data:{...state.data}
        };
    case CONSTANTS.CLEAR:
        return initState
    default:
      return state
  }
}