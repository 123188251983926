
let initState = {
  parameterActive: 0,
  updatedAt: '',
  alertList: [
  ],
  reading: []
}

export function updateview(state = initState, action) {
  //console.log(state)
  switch (action.type) {
    case "LIVE_DATA":
      let __state = { ...state }

      let found = false
      for (let i = 0; i < state.reading.length; i++) {
        if (state.reading[i].paramId === action.data.paramId && state.reading[i].sensId === action.data.sensorId) {
          //    console.log('===========******=========')
          __state.reading[i].value = action.data.reading
          __state.reading[i].updatedAt = action.data.updatedAt
          found = true
        }
      }

      if (!found) {
        __state.reading.push({
          value: action.data.reading,
          paramId: action.data.paramId,
          sensId: action.data.sensorId,
          updatedAt: action.data.updatedAt,
        })
      }

      let activeSensor = __state.reading.length

      return {
        ...__state,
        parameterActive: activeSensor,
        updatedAt: action.data.updatedAt
      }
    case "LIVE_LOW_ALERT_DATA":

      let _state = { ...state }

      let _found = false
      for (let i = 0; i < state.alertList.length; i++) {
        if (state.alertList[i].paramId === action.data.paramId && state.alertList[i].sensorType === action.data.sensorType) {
         
          _state.alertList[i].value = action.data.value
          _state.alertList[i].updatedAt = action.data.updatedAt
          _state.alertList[i].isAlert = action.data.isAlert
          _state.alertList[i].pdId = action.data.pdId
          _found = true
        }
      }

      if (!_found) {
        _state.alertList.push({
          value: action.data.value,
          paramId: action.data.paramId,
          sensorType: action.data.sensorId,
          tankName: action.data.tankName,
          locationName: action.data.locationName,
          message: "Reading value less than minimum value",
          updatedAt: action.data.updatedAt,
          pId: action.data.pId,
          sId: action.data.sId,
          tId: action.data.tId,
          lId: action.data.id,
          pdId: action.data.pdId
        })
      }

      return {
        ..._state,
        updatedAt: action.data.updatedAt
      }
    case "LIVE_HIGH_ALERT_DATA":

      let ___state = { ...state }

      let __found = false
      for (let i = 0; i < state.alertList.length; i++) {
        if (state.alertList[i].paramId === action.data.paramId && state.alertList[i].sensorType === action.data.sensorType) {
          //    console.log('===========******=========')
          ___state.alertList[i].value = action.data.value
          ___state.alertList[i].updatedAt = action.data.updatedAt
          ___state.alertList[i].isAlert = action.data.isAlert
          ___state.alertList[i].pdId = action.data.pdId
          __found = true
        }
      }

      if (!__found) {
        ___state.alertList.push({
          value: action.data.value,
          paramId: action.data.paramId,
          sensorType: action.data.sensorType,
          tankName: action.data.tankName,
          locationName: action.data.locationName,
          message: "Reading value higher than maximum value",
          updatedAt: action.data.updatedAt,
          isAlert: action.data.isAlert,
          pId: action.data.pId,
          sId: action.data.sId,
          tId: action.data.tId,
          lId: action.data.id,
          pdId: action.data.pdId
        })
      }

      return {
        ...___state,
        updatedAt: action.data.updatedAt
      }
    case "UPDATE_PARAMETER_ALERT_SUCCESS":
      var filtered = state.alertList.filter(function(el) { return el.pdId != action.result.id });

      return ({
        ...state,
        alertList: filtered
      })


    default:
      return state
  }
}